import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import MainScreen from "../components/page-parts/main-screen"
import Services from "../components/page-parts/services"

import 'swiper/swiper-bundle.css'
import Technologies from "../components/page-parts/technologies"
import Portfolio from "../components/page-parts/portfolio"
import AboutSection from "../components/page-parts/about-section"
import FormSection from "../components/page-parts/form-section"

import Illustration from '../svg/main-banner-illustration.svg'


const IndexPage = () => {

  const content = {
    ttlBlue: <span>разработаем сайт</span>,
    title: ' для вашего бизнеса',
    subtitle: 'Проанализируем конкурентов, разработаем дизайн, настроим рекламу, подключим CRM-систему. Используем самые современные технологии веб-разработки',
    buttonText: 'наши услуги',
    buttonLink: '#services-section',
  }

  return (
    <Layout fixedHeader={true}>
      <Seo />
      <MainScreen svg={<Illustration/>} content={content} />
      <Services />
      <Technologies />
      <Portfolio />
      <AboutSection />
      <FormSection />
    </Layout>
  )
}

export default IndexPage
