import React from 'react'
import { makeStyles } from '@material-ui/styles';
import { Box, Container, Hidden, Typography } from '@material-ui/core';
import ServicesSlider from '../sliders/services-slider';


const useStyles = makeStyles((theme) => ({

  wrapper: {
    padding: '60px 0 90px 0',
    position: 'relative',
  },

  title: {
    marginBottom: '1rem',
  },

  [theme.breakpoints.up('md')]: {

    wrapper: {
      zIndex: 5,
      overflow: 'hidden',
    },

    content: {
      position: 'relative',
      zIndex: 4,
    },

    circle: {
      position: 'absolute',
      borderRadius: '50%',
      background: 'linear-gradient(180deg, #ffffff 0%, #c9c9c9 100%)',
      opacity: .3,
      width: '1107px',
      height: '1107px',
      left: '50vw',
      top: '-629px',
      zIndex: 1,
    },
  },

}))

const Services = () => {

  const classes = useStyles()

  return (
    <Box
      component="section"
      className={classes.wrapper}
      id="services-section"
    >
      <Hidden xsDown>
        <div className={classes.circle}></div>
      </Hidden>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Box className={classes.titleBox} mb={{xs: 5, md: 7}}>
            <Typography variant="h2" className={`${classes.title} title`}>наши маркетинговые <span>услуги</span></Typography>
            <Typography variant="body1" className="subtitle"> – Решаем любые задачи для вашего бизнеса</Typography>
          </Box>
          <Box className={classes.sliderBox}>
            <ServicesSlider />
          </Box>
        </Box>
      </Container>
    </Box>
  )
}

export default Services
