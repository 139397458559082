import React, { useRef } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation, Autoplay } from 'swiper'
import { Box, Button } from '@material-ui/core'
import Logo from '../../svg/logo-grey.svg'
import Img1 from '../../svg/services-img-1.svg'
import Img2 from '../../svg/services-img-2.svg'
import Img3 from '../../svg/services-img-3.svg'
import Img4 from '../../svg/services-img-4.svg'
import ArrowIcon from '../../svg/arrowRight.svg'
import { Link } from 'gatsby'


SwiperCore.use([Navigation, Autoplay])


const useStyles = makeStyles((theme) => ({

  wrapper: {
    position: 'relative',
  },

  logoBox: {
    marginBottom: '80px',
  },

  outer: {
    padding: '20px',
  },

  inner: {
    // border: '0.5px solid #414141',
    background: '#fff',
    boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '20px',
    transition: 'transform .3s linear',

    '&:hover': {
      transform: 'translateY(-10px)',
    }
  },

  bgImg: {
    background: [theme.palette.gradientBlue.main],
    width: '100%',
    height: '129px',
    position: 'relative',
    marginBottom: '80px',
  },

  image: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
  },

  body: {
    textAlign: 'center',
  },

  title: {
    position: 'relative',

    '&:after': {
      content: '""',
      display: 'block',
      width: '190px',
      height: '3px',
      background: [theme.palette.gradientBlue.main],
      margin: '8px auto',
    },
  },

  description: {
    marginBottom: '16px',
  },

  btnBox: {
    '& button': {
      fontWeight: 500
    },
  },

  linkToService: {
    textDecoration: 'none',
  },

  [theme.breakpoints.up('md')]: {
    description: {
      height: '125px',
    }
  },

  '@media (min-width: 1024px)': {
    description: {
      height: '175px',
    }
  },

  [theme.breakpoints.up('lg')]: {
    description: {
      height: '150px',
    }
  },
  
}))

const ServicesSlider = () => {

  const prevRef = useRef(null)
  const nextRef = useRef(null)

  const classes = useStyles()

  const slides = [
    {
      title: 'Разработка сайтов',
      desc: 'Профессиональный, продуманный дизайн сайта в стеке с современными технологиями разработки будут привлекать и вдохновлять ваших клиентов',
      link: '/services/web-dev',
      img: <Img1/>,
    },
    {
      title: 'Интернет реклама',
      desc: 'Проведем анализ ваших конкурентов, выделим сильные стороны вашего бизнеса, соберем семантическое ядро и настроим рекламу в Яндекс и Google',
      link: '/services/marketing',
      img: <Img2/>,
    },
    {
      title: 'Дизайн',
      desc: 'Разработаем для вас логотип и фирменный стиль. При необходимости готовы предоставить полный спектр услуг по графическому и web дизайну',
      link: '/services/design',
      img: <Img3/>,
    },
    {
      title: 'Автоматизация',
      desc: 'Автоматизируем ваш сайт, что позволит вам не только самостоятельно управлять содержанием сайта, но  решать ряд задач по заранее разработанному алгоритму',
      link: '#!',
      img: <Img4/>,
    },
  ]


  return (
    <Box className={classes.wrapper}>
      <Swiper
        id="services-swiper"
        className={classes.swiper}
        spaceBetween={0}
        slidesPerView={1}
        speed={1000}
        autoplay={{
          delay: 2000
        }}
        navigation={{
          prevEl: '.services-sw-btn-prev',
          nextEl: '.services-sw-btn-next',
        }}
        breakpoints={{
          768: {
            slidesPerView: 2
          },
          1024: {
            slidesPerView: 3
          },
          1440: {
            slidesPerView: 4,
          },
        }}
      >
        {slides.map((slide, idx) => (
          <SwiperSlide className={classes.slide} key={`${idx}-servicesSlide`}>
            <Box className={classes.outer}>
              <Box className={classes.inner} pt={1} pb={2}>
                <Box
                  className={classes.logoBox}
                  display="flex"
                  justifyContent="center"
                >
                  <Logo />
                </Box>
                <Box className={classes.bgImg}>
                  <Box className={classes.image}>
                    {slide.img}
                  </Box>
                </Box>
                <Box className={classes.body} px={2}>
                  <h2 className={classes.title}>{slide.title}</h2>
                  <p className={classes.description}>{slide.desc}</p>
                  <Box className={classes.btnBox}>
                    <Link className={classes.linkToService} to={slide.link}>
                      <Button color="primary" variant="contained" endIcon={<ArrowIcon />}>Подробнее</Button>
                    </Link>
                  </Box>
                </Box>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
      <div ref={prevRef} className="swiper-button-prev services-sw-btn-prev" />
      <div ref={nextRef} className="swiper-button-next services-sw-btn-next" />
    </Box>
  )
}

export default ServicesSlider
